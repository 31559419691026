import React from "react"
import Link from "gatsby-link"
import Layout from "../components/Layout"

const NotFoundPage = () => (
  <Layout meta={{ title: "Page not found" }}>
    <div className="container text-center mt-5 pt-5">
      <h1 className={"mt-5"}>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      <Link to={"/episodes"} className={"btn btn-primary"}>
        Watch latest episodes
      </Link>
    </div>
  </Layout>
)

export default NotFoundPage
